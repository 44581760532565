<template>
  <div
    class="
      menu-wrapper animate__animated animate__animated animate__slideInLeft
    "
  >
    <div class="menu">
      <div class="menu-head">
        <v-card-title style="background-color: #f4ae00" class="white--text">
          <v-list-item :key="1231">
            <v-list-item-avatar color="primary">
              <v-avatar color="white">
                <v-icon color="#8cd6fb" dark> mdi-account </v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="`${user.name} ${user.surname}`"
              ></v-list-item-title>
              <!-- v-text="user.idNumber" -->
              <v-list-item-subtitle></v-list-item-subtitle> </v-list-item-content
            ><v-list-item-action @click="close('/')">
              <v-btn icon>
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action></v-list-item
          >
        </v-card-title>
      </div>
      <md-list class="list-items">
        <md-list-item to="/">
          <md-icon>home</md-icon>
          <span class="md-list-item-text">Home</span>
        </md-list-item>
        <!-- <md-list-item to="/child-profile">
          <md-icon>people</md-icon>
          <span class="md-list-item-text">Children X</span>
        </md-list-item> -->
        <md-list-item to="/profile">
          <md-icon>account_circle</md-icon>
          <span class="md-list-item-text">Profile</span>
        </md-list-item>
        <md-list-item to="/messages">
          <md-icon>mail</md-icon>
          <span class="md-list-item-text">Messages</span>
        </md-list-item>
        <!--   <md-list-item to="/lift-history">
          <md-icon>place</md-icon>
          <span class="md-list-item-text">Lift History X</span>
        </md-list-item> -->
        <md-list-item to="/settings">
          <md-icon>settings</md-icon>
          <span class="md-list-item-text">Settings</span>
        </md-list-item>
      </md-list>
      <div class="btn-section">
        <v-btn @click="logout" width="100%" color="white" outlined rounded
          >Logout</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "./RoundButton.vue";
import { mapActions } from "vuex";
import { getUser, deleteDB } from "../helpers/db";
export default {
  name: "Menu",
  components: { RoundButton },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async logout() {
      await deleteDB(this.close("/login"));
    },
    ...mapActions(["pageController", "reDirectPage"]),
    close(path = undefined) {
      console.log({ pathWAS: path });
      if (path === "/login") {
        this.reDirectPage({ show: true, path: "/logout" });
        this.$router.push({ path });
      } else {
        this.$router.go(-1);
      }
    },
  },
  async mounted() {
    let user = await getUser();
    this.user =
      user.length > 0
        ? user[0]
        : {
            name: "Guest",
            surname: "",
            idNumber: "",
          };
    if (user.length === 0) this.$router.push({ path: "/login" });
    this.pageController();
    //    document.querySelector('.menu-wrapper').classList.remove('animate__slideInLeft')
    // document.querySelector('.menu-wrapper').classList.add('animate__slideOutLeft')
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log({ to, from, next });

  //   document
  //     .querySelector(".menu-wrapper")
  //     .classList.remove("animate__slideInLeft");
  //   document
  //     .querySelector(".menu-wrapper")
  //     .classList.add("animate__slideOutLeft");
  //   setTimeout(() => {
  //     next();
  //   }, 350);
  // },
};
</script>

<style>
</style>