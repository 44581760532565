<template>
  <div class="payment-wrapper">
    <div class="payment-method">
      <div class="visa-logo">
        <img src="../assets/visa-logo.svg" alt="visa logo" />
      </div>
      <div class="input-section">
        <form action="#">
          <CardForm
            :form-data="formData"
            @input-card-number="updateCardNumber"
            @input-card-name="updateCardName"
            @input-card-month="updateCardMonth"
            @input-card-year="updateCardYear"
            @input-card-cvv="updateCardCvv"
          />
          <md-checkbox v-model="check" class="md-primary"
            >Save Card</md-checkbox
          >
        </form>
      </div>
    </div>
    <div style="height: 50px; background-color: #00d8ef" class="bottom-btn">
      <bottom-button style="color: #d8f2ff; height: 100%" />
    </div>
  </div>
</template>

<script>
import CardForm from "../components/CardFields.vue";
import BottomButton from "../components/BottomButton.vue";
import Nav from "../components/NavBar.vue";
import { mapActions } from "vuex";
export default {
  components: { Nav, BottomButton, CardForm },
  name: "PaymentMethod",
  data() {
    return {
      email: "name@mail.com",
      check: true,
      formData: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
    };
  },
  methods: {
    ...mapActions(["pageController"]),
    updateCardNumber(val) {},
    updateCardName(val) {},
    updateCardMonth(val) {},
    updateCardYear(val) {},
    updateCardCvv(val) {},
  },
  mounted() {
    console.log(this);
    let page = {
      title: "Account",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
  },
};
</script>
<style>
input {
  margin-bottom: 0 !important;
}
.md-checkbox label {
  font-weight: bold;
  color: #707070;
  padding: 0;
}
</style>