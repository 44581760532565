
let client = null
let _url = null
export default class Websocket {
  constructor (url) {
    if(url !==_url){
      if (client !== null) {
        client.close(1000, 'Work complete')
      }
      client = null
    }
    this.url = url
    _url = url
    this.tries = 0
  }

  async connect () {
    return new Promise(function (resolve, reject) {
      if(client !== undefined && client !== null){
        if(client.readyState === 3 || client.readyState === 2 ){
          const theClient = new WebSocket(_url)
          theClient.onopen = function () {
            resolve(theClient)
          }
          theClient.onerror = function (err) {
            reject(err)
          }
        }
        else {
          resolve(client)
        }
      }
      else{
        const theClient = new WebSocket(_url)
        theClient.onopen = function () {
          resolve(theClient)
        }
        theClient.onerror = function (err) {
          reject(err)
        }
      }
      
    })
  }

  sendDataSync (inData, callback) {
    client.send(JSON.stringify(inData))
    client.onmessage = function (event) {
      try {
        // console.log(event.data)
        // console.log('PARSE TO JSON')
        callback(JSON.parse(event.data))
      } catch (e) {
        console.log(e)
        // console.log('SEND AS STRING')
        callback(event.data)
      }
    }

    client.onerror = function (_err) {
      callback('ERROR')
    }
  }

  async sendData (inData) {
    return new Promise((resolve, reject) => {
      client.send(JSON.stringify(inData))
      client.onmessage = function (event) {
        try {
          // console.log('PARSE TO JSON')
          resolve(JSON.parse(event.data))
        } catch (e) { resolve(event.data)
        }
      }

          // console.log('SEND AS STRING')
  
      client.onerror = function (err) {
        reject(err)
      }
    })
  }

  getDataFromSocketSync (inData, callback) {
    this.connect().then((theClient) => {
      client = theClient
      console.log('connected')
      this.sendDataSync(inData, callback)
    }).catch((_err) => {
      // callback('ERROR')
    })
  }

  async getDataFromSocket (inData) {
    try {
      client = await this.connect()
      //console.log(client)
      console.log('connected')
      const result = await this.sendData(inData)
      console.log({result})
      return result
    } catch (e) {
      console.log(e)
      if (this.tries > 5) {
        console.log(`TRIES ${this.tries}`)
        return 'ERROR'
      }
      setTimeout(async () => {
        this.tries++
        return (await this.getDataFromSocket(inData))
      }, 800)
      if (this.tries >= 5) {
      throw e;}
    }
  }

//   async getData (inData) {
//     if (this.tries > 5) {
//       console.log(`TRIES ${this.tries}`)
//       return 'ERROR'
//     }

//     if (client == null) {
//       client = new WebSocket(this.url)
//     }
//     console.log(client.readyState)
//     if (client.readyState === 3) {
//       client = new WebSocket(this.url)
//     }
//     try {
//       client.send(JSON.stringify(inData))
//     } catch (e) {
//       if (client.readyState === 1) {
//         client.onopen = function (event) {
//           // console.log(event)
//           client.send(JSON.stringify(inData))
//         }
//       } else {
//         setTimeout(async () => {
//           this.tries++
//           return (await this.getData(inData))
//         }, 500)
//       }
//     } finally {
//       client.onmessage = function (event) {
//         try {
//           // console.log('PARSE TO JSON')
//           return (JSON.parse(event.data))
//         } catch (e) {
//           // console.log('SEND AS STRING')
//           // console.log(event.data)
//           return (event.data)
//         }
//       }
//     }
//     /* return new Promise((resolve, reject) => {

//     }) */
//   }

//   getDataSync (inData, callback) {
//     if (this.tries > 20) {
//       throw `Could not connect to Web socket ${this.url}}`
//     }
//     if (client == null) {
//       client = new WebSocket(this.url)
//     }
//     if (client.readyState === 3) {
//       client = new WebSocket(this.url)
//     }
//     try {
//       client.send(JSON.stringify(inData))
//     } catch (e) {
//       if (client.readyState === 1) {
//         client.onopen = function (event) {
//           // console.log(event)
//           client.send(JSON.stringify(inData))
//         }
//       } else {
//         setTimeout(() => {
//           this.tries++
//           this.getDataSync(inData, callback)
//           // callback(await this.getData(inData))
//         }, 500)
//       }
//     } finally {
//       client.onmessage = function (event) {
//         try {
//           // console.log('PARSE TO JSON')
//           callback(JSON.parse(event.data))
//         } catch (e) {
//           console.log('SEND AS STRING')
//           // console.log(event.data)
//           callback(event.data)
//         }
//       }
//     }
//   }

  closeConnection () {
    if (client !== null) {
      client.close(1000, 'Work complete')
    } else {
      // console.log('Client is null')
    }
  }

  averageGeolocation (coords) {
    if (coords === undefined) return
    if (coords.length === 1) {
      return coords[0]
    }

    let x = 0.0
    let y = 0.0
    let z = 0.0

    for (const coord of coords) {
      const latitude = coord.latitude * Math.PI / 180
      const longitude = coord.longitude * Math.PI / 180

      x += Math.cos(latitude) * Math.cos(longitude)
      y += Math.cos(latitude) * Math.sin(longitude)
      z += Math.sin(latitude)
    }

    const total = coords.length

    x = x / total
    y = y / total
    z = z / total

    const centralLongitude = Math.atan2(y, x)
    const centralSquareRoot = Math.sqrt(x * x + y * y)
    const centralLatitude = Math.atan2(z, centralSquareRoot)

    return {
      latitude: centralLatitude * 180 / Math.PI,
      longitude: centralLongitude * 180 / Math.PI
    }
  }
}

// export default new Websocket()
export const interfaceAPI = (msg, callback)=>{
    console.log("WOrking", msg);
  const websocket = new Websocket(process.env.VUE_APP_API_URL);
  return websocket.getDataFromSocketSync(msg,callback)
}

export const asyncInterfaceAPI = async (msg)=>{
  const websocket = new Websocket(process.env.VUE_APP_API_URL);
  console.log(msg)
  return await websocket.getDataFromSocket(msg);
}

// export const getDataFromNavSocket = async (msg)=>{
//   client = null
//   const websocket = new Websocket(process.env.VUE_APP_NAV_SERVER_URL)
//   return await websocket.getDataFromSocket(msg)
// }

// export const getDataFromSocketSync = (msg,callback)=>{
//   const websocket = new Websocket(process.env.VUE_APP_NAV_SERVER_URL)
//   return websocket.getDataFromSocketSync(msg,callback)
// }
// export const getDeviceDataFromSocketSync = (msg,callback)=>{
//   const websocket = new Websocket(process.env.VUE_APP_WEBSOCKET_URL)
//   return websocket.getDataFromSocketSync(msg,callback)
// }