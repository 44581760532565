<template>
  <div class="text-center center h10" v-if="isLoading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <div
    v-else
    class="
      mobile-wrapper
      animate__animated animate__animated animate__slideInRight
    "
  >
    <div class="lift-details">
      <v-card outlined color="grey"

        v-for="(trip, index) in selectedChildTrips"
        :key="index"
        class="lift-item"
      >
        <div id="mapid" class="map">
          <trip-map :path="trip.path" />
        </div>
        <v-card max-width="100%" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 mb-1">
                {{ trip.dropOffAddress }} <br />
                Arrival time,{{ trip.startTime.split(" ")[1] }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ trip.pickupAddress }} <br />
                Departure time,{{
                  trip.endTime.split(" ")[1]
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card>
    </div>
  </div>
</template>
<script>
import { latLng, Icon, latLngBounds } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";
import { mapActions } from "vuex";
import { asyncInterfaceAPI } from "../helpers/Websocket";
import {sleep} from '../helpers/commonTasks'
import TripMap from "../components/TripMap.vue";
export default {
  name: "LiftDetails",
  components: {
    LMap,
    LTileLayer,
    TripMap,
  },

  data() {
    return {
      isLoading: true,
      items: [1, 2, 93, 4, 25, 6, 7, 8, 19],
      selectedChildTrips: undefined,
    };
  },
  methods: {
    ...mapActions(["pageController"]),
    date() {
      let date = new Date();
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      // return `${day}-${month}-${year}`;
      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {
    await sleep(3500)
    let id = this.$route.params.id;
    let page = {
      title: "Lift History",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
    let msg = {
      action: "GETSCHOLARTRIPSHISTORY",
      idNumber: id,
      tripDate: this.date(),
    };
    console.log({ msg, id });
    const data = await asyncInterfaceAPI(msg)
    console.log("IS WORIKIMN");
    console.log({ dataTrips: data });
    this.selectedChildTrips = data;
    // this.updateCurrentTrips(data);
    this.isLoading = false;
    
    // interfaceAPI(msg, (data) => {
    //   console.log("IS WORIKIMN");
    //   console.log({ dataTrips: data });
    //   this.selectedChildTrips = data;
    //   // this.updateCurrentTrips(data);
    //   this.isLoading = false;
    // });
  },
};
</script>

<style>
</style>