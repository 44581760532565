<template>
  <div class="list-child">
    <!-- <div class="toogle-map">
      <v-switch
        v-model="isSatillite"
        inset
      ></v-switch>
        <!-- :label="`${isSatillite.toString()}`" 
    </div> -->
    <div class="avatar-section">
      <div
        v-if="children.length > 0"
        v-for="(child, index) in children"
        :key="index"
      >
        <v-avatar @click="selectChild(child)" class="white-bg">
          <img
            style="width: 100%; height: 100%"
            :src="`https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=${child.name}`"
          />
        </v-avatar>
        <span>
          {{ child.name }}
        </span>
      </div>
      <div class="items-loading" v-if="isLoading" key="89w2">
        <v-avatar class="white-bg">
          <!-- <v-btn height="45" width="45" icon color="white" fab dark> -->
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <!-- </v-btn> -->
        </v-avatar>
        <span> Loading... </span>
      </div>
      <!-- <div class="add" key="2">
        <v-avatar class="white-bg">
          <v-btn height="45" width="45" icon color="white" fab dark>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-avatar>
        View All
      </div> -->
      <div @click="navigateTo('/add-child')" class="add" key="3782">
        <v-avatar class="white-bg">
          <v-btn height="45" width="45" icon color="white" fab dark>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-avatar>
        <span> Add Child </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getChild } from "../helpers/db";
export default {
  name: "ListChild",
  data() {
    return {
      isSatillite: true,
      children: [],
      isLoading: true,
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push({ path });
    },
    selectChild(data) {
      this.$emit("select-child", data);
    },
  },
  watch: {
    isSatillite: function () {
      e;
      console.log("ISSEt", this.isSatillite);
      // let el = document.querySelector('.v-input--switch__thumb.theme--light');
      // console.log(el);
      // el.innerHTML =  `<span style='color:${this.isSatillite?"white":"#1976d2"};position: absolute;top: 7px;'>${this.isSatillite? 'Saat':'2d View'}</span>`
    },
  },
  created() {
    //  setTimeout(() => {
    //    let el = document.querySelector('.v-input--switch__thumb.theme--light');
    //    console.log(el);
    //   if (el) el.innerHTML =  `<span style='color:${this.isSatillite?"white":"unset"};position: absolute;top: 7px;'>${this.isSatillite? 'Saat':'2d'}</span>`
    //  }, 2000);
  },
  mounted() {
    setTimeout(async () => {
      let children = await getChild();
      this.children = children;
      this.isLoading = false;
      console.log({ children });
      if (this.$route.path === "/profile") {
        this.selectChild(this.children[0]);
      }
    }, 2000);
  },
};
</script>

<style>
</style>