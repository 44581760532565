
const state = {
    currentTrips: []
}
const getters = {
    getCurrentTrips: (state) => state.currentTrips
}
const actions = {
    updateCurrentTrips({commit}, data){
        commit('setCurrentTrips', data)
    }
}

const mutations = {
    setCurrentTrips: (state, currentTrips) => (state.currentTrips = currentTrips)
}
export default {
    state,
    getters,
    actions,
    mutations,
  };
  