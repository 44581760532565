export const db = new Dexie("RenaApp");
console.log(new Dexie())
db.version(7).stores({
  user: "_key,cellphone,name,surname,idNumber,profilePic,homeAddress,settings,homeLocation,children",
  children: "_key,idNumber,name,surname,profilePic,schoolName, schoolAddress,schoolLocation",
});
db.open();

let placeHolder = {
  address: "",
  homeLocation: [],
  children: [],
  settings: {
    sendDrivingAlert: 1,
    sendPickUpAlert: 1,
    sendDropOffAlert: 1
  }
};

export const userLogged = async () => {
  const user = await db.user.toArray();
  console.log({
    user
  });
  return user.length === 1;
};
export const deleteDB = async (callback) => {
  await db.delete().then(() => {
    console.log("Database successfully deleted");
  }).catch((err) => {
    console.error("Could not delete database", {
      err
    });
  }).finally(async () => {
    // window.location.href = "/login"
  });
  console.log({
    callback
  });
  await callback()
};

export const getUser = async () => {
  const user = await db.user.toArray();
  if (user.length === 1) {
    return user;
  }
  return [];
};

export const removeChild = async (idNumber) => {
  const user = await db.user.toArray();
  if (user.length > 0) {
    await db.user
      .where("cellphone")
      .equals(cellphone)
      .modify({
        children: children.filter((child) => child.idNumber !== idNumber),
      });
    await db.children
      .where("idNumber")
      .equals(idNumber)
      .delete();
  }
};
export const getChild = async (idNumber) => {
  const children = idNumber ?
    await db.children.where("idNumber").equals(idNumber) :
    await db.children.toArray();
  console.log("getChild", children);
  return children;
};
export const addChild = async (payload) => {
  const user = await db.user.toArray();
  console.log(user[0].children, payload.idNumber);
  if (user.length > 0) {
    await db.user
      .where("cellphone")
      .equals(user[0].cellphone)
      .modify({
        children: [...user[0].children, payload.idNumber],
      });
    await db.children.add({
      _key: payload.idNumber,
      ...payload
    });
  }
  // else {
  //     await db.children.add(payload)
  // }
};
export const CreateOrUpdateUser = async (payload) => {
  const user = await db.user.toArray();

  if (user.length === 1) {
    console.log("moDIFYING", user[0].cellphone);
    await db.user
      .where({
        _key: user[0]._key,
      })
      .modify(payload);
  } else {
    console.log({
      ...payload,
    });
    console.log({
      ...placeHolder,
      ...payload
    });
    await db.user.add({
      ...placeHolder,
      ...payload
    });
  }

  let updatedUser = await db.user.toArray()

  console.log({
    updatedUser
  });
};