<template>
  <div class="popup-mssg elevation-3">
    <div class="inner">
      <p style="color: red" class="bold text-h4 font-weight-bold">EISH!</p>
      <h6 id="text-h5" class="text-h5 blue-text">Lorem, ipsum</h6>
      <p class="transition-swing text-subtitle-1 blue-text">
        Lorem ipsum dolor sit amet
      </p>
      <v-btn style="color: white" color="red" elevation="2" rounded>TRY AGAIN</v-btn>
    </div>
  </div>
</template>

<script>
export default {
    name: "PopUpMessage",
    props: {
        theme: {
            type: String,
            default: "error"
        } 
    }
};
</script>

<style>
</style>