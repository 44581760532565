<template>
  <l-map ref="map" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-polyline
      v-if="hasPath && path"
      :lat-lngs="polyline.latlngs"
      :color="polyline.color"
    ></l-polyline>
    <l-marker
      v-if="hasPath && path && markerA"
      :icon="iconA"
      class="point-a"
      :lat-lng="markerA"
    >
    </l-marker>
    <v-rotated-marker
      v-if="hasPath && path && markerB"
      :icon="iconB"
      class="point-b"
      :lat-lng="markerB"
      :rotationAngle="pointB.heading"
    >
    </v-rotated-marker>
  </l-map>
</template>

<script>
import L from "leaflet";
import { latLng, Icon, latLngBounds } from "leaflet";
import { LMap, LTileLayer, LMarker, LPolyline, LIcon } from "vue2-leaflet";
import Websocket from "../helpers/Websocket";
import { mapActions } from "vuex";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
export default {
  name: "Leaflet",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  props: {
    currentChild: Object,
    path: Array,
    hasPath: Boolean,
  },
  methods: {
    ...mapActions(["showModal"]),
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    success(pos) {
      var crd = pos.coords;
      this.center = latLng([crd.latitude, crd.longitude]);
      this.mapBounds = latLngBounds([[crd.latitude, crd.longitude]]);
    },
    error(err) {
      console.warn(`ERROR: (${err.code}): ${err.message}`);
    },
    deviceLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error, {
        enableHighAccuracy: true,
      });
    },
  },
  updated() {
    console.log("UPdatED");
  },
  watch: {
    async path(path) {
      console.log({ PATHVAL: path });
      setTimeout(async () => {
        console.log("PATHH", path);
        if (path !== undefined) {
          console.log("pathIS", path);
          let websocket = new Websocket(process.env.VUE_APP_API_URL);
          // let tripData = this.path
          let tripData = path;
          let latlanPoints = [];
          this.pointA = tripData[0];
        this.pointB = tripData[tripData.length - 1];
          this.markerA = [this.pointA.latitude, this.pointA.longitude];
          this.markerB = [this.pointB.latitude, this.pointB.longitude];
          console.log({ markerA: this.markerA, markerB: this.markerB });
          tripData.map((m) => {
            // this.path.map((m) => {
            latlanPoints.push([
              parseFloat(m.latitude),
              parseFloat(m.longitude),
            ]);
          });

          let centre = websocket.averageGeolocation(tripData);
          this.center = [centre.latitude, centre.longitude];
          this.mapBounds = await latLngBounds(latlanPoints);
          this.polyline.latlngs = latlanPoints;

          this.$nextTick(() => {
            this.$refs.map.mapObject.fitBounds(latlanPoints);
          });
        }
      }, 800);
    },
  },
  data() {
    return {
      pointA: {},
      pointB: {},
      markerA: undefined,
      markerB: undefined,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 14.2,
      iconSize: 30,
      center: [-33.8813833, 18.6875349],
      centerN: [-33.8813833, 18.6875349],
      markerLatLng: [-33.8813833, 18.6875349],
      mapBounds: latLngBounds([
        [-23.157, 30.0759183],
        [-34.1565133, 18.6004583],
      ]),
      polyline: {
        latlngs: [],
        color: "#27adff",
      },
      iconA: L.icon({
        iconUrl: "/images/Rectangle1476.png",
        iconSize: [22, 22],
        // iconAnchor: [16, 37],
      }),
      iconB: L.icon({
        iconUrl: "//app.quickloc8.co.za/images/ic_new_white_taxi.png",
        iconSize: [50, 50],
        iconAnchor: [10, 22],
      }),
    };
  },
  async mounted() {
    this.deviceLocation();
  },
};
</script>

<style>
</style>