<template>
  <div class="welcome-wrapper animate__animated animate__animated animate__slideInRight">
    <div class="welcome">
      <div class="menu-btn">
        <img src="../assets/Menu-14.png" alt="rena menu icon" />
      </div>
    </div>
    <div class="bottom-btn">
      <bottom-button
        style="color: #fff; font-size: 12px"
        v-text="'View terms and conditions.'"
      ></bottom-button>
      <bottom-button
        text="DONE"
        style="background-color: #32d7f2; color: #d8f2ff"
      />
    </div>
  </div>
</template>

<script>
import BottomButton from "./BottomButton.vue";
import { mapActions } from "vuex";
export default {
  components: { BottomButton },
  methods: {
    ...mapActions(["pageController"]),
  },
  mounted() {
    this.pageController();
  },
};
</script>

<style>
</style>