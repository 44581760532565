import Vue from 'vue'
import Vuex from 'vuex'
import pageController from "./modules/page"
import trips from "./modules/trips"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    pageController,
    trips,
  }
});
