import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueMaterial from "vue-material";
// import vuetify from '@/plugins/vuetify'
import "./assets/css/style.css";
import "./assets/css/main.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/css/vue-material.css";
import "./assets/css/default-theme.css";
import i18n from "./i18n"
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);
// import MaterialKit from "./plugins/material-kit";
Vue.config.productionTip = false;
Vue.use(VueMaterial);
// Vue.use(MaterialKit);
new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify(theme),
  render: (h) => h(App),
}).$mount("#app");


const theme = {
    theme: {
      themes: {
        light: {
          primary: "#8cd6fb",
          secondary: "#FFCCBC",
          accent: "#2e302f",
          error: "#fe2a39",
          darker: "#2b98f7",
          success: "#15C057",
        },
      },
    },
    icons: {
      iconfont: "mdi" || "md",
      values: {},
    },
  };
  