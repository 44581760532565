<template>
  <v-app id="v-app">
    <div id="app-wrapper">
      <nav-bar v-show="page.show" :page="page" />
      <v-snackbar
        v-if="redirected.show"
        :timeout="-1"
        max-width="290px"
        :value="true"
        centered
        color="error lighten-1"
        elevation="24"
      >
        Redirected from <code>{{ redirected.path }}</code>
      </v-snackbar>
      <v-snackbar
        max-width="290px"
        v-if="modal.show"
        :timeout="-1"
        :value="true"
        centered
        :color="`${modal.theme} lighten-1`"
        elevation="24"
      >
        <b>
          {{ modal.mssg }}
        </b>
      </v-snackbar>
      <router-view />
    </div>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          {{ messageText }}
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<style>
#app-wrapper {
  background-color: #8cd6fb;
  height: 100vh;
}
</style>
<script>
///const match = await bcrypt.compare(password, user.passwordHash);
import { mapGetters, mapActions } from "vuex";
import NavBar from "./components/NavBar.vue";
import { v4 as uuidv4 } from "uuid";
import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import { interfaceAPI } from "./helpers/Websocket";
import { userLogged } from "./helpers/db";
export default {
  components: { NavBar },
  data() {
    return {
      password: "mostsecureever",
      sheet: false,
      messageText:'',
      //  redirected: { show: false, path: "" },
    };
  },
  watch: {
    modal() {
      if (this.modal.show) {
        setTimeout(() => {
          this.showModal({ show: false, theme: "error", mssg: "" });
        }, 3000);
      }
    },
    redirected() {
      console.log("NOT RILY");

      if (this.redirected.show) {
        setTimeout(() => {
          this.reDirectPage({ show: false, path: "" });
        }, 3000);
      }
    },
  },
  methods: {
    ...mapActions(["reDirectPage", "showModal"]),
    success(pos) {
      var crd = pos.coords;
      console.log({ crd });
    },
    error(err) {
      console.warn(`ERROR: (${err.code}): ${err.message}`);
    },
    deviceLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error, {
        enableHighAccuracy: true,
      });
    },
  },
  computed: {
    ...mapGetters({ redirected: "getReDirect" }),
    ...mapGetters({ page: "getPage" }),
    ...mapGetters({ modal: "getModal" }),
  },
  async mounted() {
    console.log(this);
    this.$router.push({ path: "login" });
    this.deviceLocation();
    
    document.querySelectorAll('meta[name="theme-color"]').forEach((el) => {
      el.setAttribute(
        "content","#83d6ff"
      );
    });
    const messaging = firebase.messaging()
    console.log(messaging)
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      // Update the UI to include the received message.
      this.messageText = payload.notification.body
      this.sheet = true      
    });
  },
};
</script>
