<template>
  <div class="mobile-wrapper animate__animated animate__animated animate__slideInRight">
    <div class="h90">
      <div class="logo-section">
        <img src="../assets/images/rena-logo.png" alt="logo" />
      </div>
      <div class="input-section">
        <form @submit.prevent="post" action="#">
          <md-field>
            <md-icon>person</md-icon>
            <label>Name</label>
            <md-input
              type="text"
              itemid="name"
              name="Name"
              v-model="name"
            ></md-input>
          </md-field>
          <md-field>
            <md-icon>person</md-icon>
            <label>Surname</label>
            <md-input
              type="text"
              itemid="lastname"
              name="Surname"
              v-model="lastname"
            ></md-input>
          </md-field>
          <md-field color="primary">
            <md-icon>account_circle</md-icon>
            <label>Email</label>
            <md-input
              type="text"
              itemid="email"
              name="Email"
              v-model="email"
            ></md-input>
          </md-field>

          <md-field>
            <md-icon>phone</md-icon>
            <label>Phone</label>
            <md-input
              type="text"
              itemid="phone"
              name="Phone"
              v-model="phone"
            ></md-input>
          </md-field>
          <md-field>
            <md-icon>badge</md-icon>
            <label>ID no</label>
            <md-input
              type="text"
              itemid="idNumber"
              name="ID Number"
              v-model="idNumber"
            ></md-input>
          </md-field>
          <md-field>
            <v-autocomplete
              class="auto-complete-bar"
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              clearable
              hide-details
              hide-selected
              item-text="description"
              item-value="symbol"
              label="Home Address"
              placeholder="Type Home Address"
              name="Home Address"
              solo
              color="primary"
              prepend-inner-icon="mdi-map-marker"
            >
              <template v-if="items.length === 0" class="search-results">
                <v-list-item>
                  <v-list-item-title>
                    Type <strong>Home Address</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="items.length > 0" class="search-results">
                <div v-for="(item, index) in items" :key="index">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.description"
                    ></v-list-item-title>
                  </v-list-item-content>
                </div>
              </template>
            </v-autocomplete>
          </md-field>
          <md-field>
            <md-icon>lock</md-icon>
            <label>Password</label>
            <md-input type="password" itemid="" v-model="password"></md-input>
          </md-field>
          <input @submit.prevent="post" type="submit" value="Submit" />
        </form>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card color="white">
        <div v-if="title">
          <v-card-title class="text-h6">
            {{ title }}
          </v-card-title>
          <v-card-text>Please make sure all fields are filled in correctly</v-card-text>
        </div>
        <v-card-text v-else>
          Please stand by
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="title" color="blue darken-1" text dark @click="dialog= false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <div class="bottom-btn">
      <bottom-button
        :text="'Already a have an account?'"
        style="color: #4dd8ee"
        @click="navigateTo('/signin')"
        @do-click="navigateTo('/signin')"
      ></bottom-button>
      <!-- @click="navigateTo('lool')" -->
      <!-- @click="post" -->
      <bottom-button
        @click="post"
        @do-click="post"
        text="Sign Up"
        style="color: #d8f2ff; background-color: #8cd6fb"
      ></bottom-button>
    </div>
  </div>
</template>

<script>
import BottomButton from "../components/BottomButton.vue";
import Nav from "../components/NavBar.vue";
import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import { interfaceAPI, asyncInterfaceAPI } from "../helpers/Websocket";
import { mapActions } from "vuex";
import { userLogged, CreateOrUpdateUser } from "../helpers/db"; //
import { v4 as uuidv4 } from "uuid";

export default {
  components: { Nav, BottomButton },
  name: "Signin",
  data() {
    return {
      email: "",
      name: "",
      lastname: "",
      phone: "",
      password: "",
      idNumber: "",
      location: {},
      //
      dialog: false,
      title: "",
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      //
      sessiontoken: "",
      currentAddress: undefined,
      locationCoords: undefined,
    };
  },
  methods: {
    ...mapActions(["pageController", "reDirectPage"]),
    navigateTo(path) {
      if (path && path !== this.$route.path) {
        this.$router.push({ path });
      }
    },
    async post() {
      let inputs = document.querySelectorAll("input");
      for (let index = 0; index < inputs.length; index++) {
        console.log(inputs[index].value);
        if (!inputs[index].value) {
            inputs[index].parentNode.classList.add('md-invalid');
          this.title = `${inputs[index].name} is required`;
          this.dialog = true
        return
        }
      };
      // let url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${this.currentAddress.place_id}&sessiontoken=${this.sessiontoken}&fields=formatted_address,geometry&key=${process.env.VUE_APP_PLACES_KEY}`;
      let msg = {
        action: "GETPLACECOORDINATES",
        placeId: this.currentAddress.place_id,
      };
      console.log(msg);
      this.locationCoords = await asyncInterfaceAPI(msg);
      console.log(this.locationCoords);
      msg = {
        action: "REGISTER",
        name: this.name,
        surname: this.lastname,
        email: this.email,
        cellphone: this.phone,
        password: this.password,
        // password: sha256(this.password).toString(CryptoJS.enc.Base64),
        token: "token here",
        idNumber: this.idNumber,
        homeAddress: this.search,
        homeLocation: {
          latitude: this.locationCoords.lat,
          longitude: this.locationCoords.lng,
        },
      };
      console.log(msg);
      const result = await asyncInterfaceAPI(msg);
      console.log(result);
      if (result === "Parent Added Succesfully") {
        await CreateOrUpdateUser({
          _key: this.phone,
          name: this.name,
          surname: this.lastname,
          email: this.email,
          cellphone: this.phone,
          idNumber: this.idNumber,
          homeAddress: this.search,
          homeLocation: {
            latitude: this.locationCoords.lat,
            longitude: this.locationCoords.lng,
          },
        });
        this.$router.push({ path: "/" });
      }
    },
  },
  watch: {
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    async search(search) {
      if (search.length < 4) return;
      console.log(this.sessiontoken);
      const msg = {
        action: "GETPLACES",
        search,
        sessiontoken: this.sessiontoken,
      };
      this.isLoading = true;
      const result = await asyncInterfaceAPI(msg);
      console.log("RESULT", result);
      this.isLoading = false;
      this.items = result;
      this.currentAddress = result[0];
      console.log(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search}&language=en&components=country:za&sessiontoken=${this.sessiontoken}&key=${process.env.VUE_APP_PLACES_KEY}`
      );
     
      this.isLoading = false;
    },
  },
  async mounted() {
    this.sessiontoken = uuidv4();
    console.log(this);
    let page = {
      title: "Sign Up",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
    //
    //
    let isLogged = await userLogged();
    console.log({ isLogged });
    if (isLogged) {
      this.reDirectPage({
        path: "/signup",
        show: true,
      });
      this.$router.push({ path: "/" });
    }
  },
};
</script>
<style>
input {
  margin-bottom: 0 !important;
}
.md-field label {
  top: 10px !important;
}
.md-focused label,
.md-has-value label {
  top: 0 !important;
}
</style>