<template>
  <div class="mobile-wrapper animate__animated animate__animated animate__slideInRight">
    <div class="terms">
      <div class="content">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident
          autem, ab quis, impedit amet sunt vero dignissimos repellendus quam
          molestiae odio cum vel repellat quidem voluptatum ullam possimus
          repudiandae ea voluptatibus? Aliquam tempore doloremque expedita
          fugiat? Perspiciatis nesciunt non fuga!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum eaque
          eveniet quia! Repellendus minima totam odio, explicabo reprehenderit
          temporibus hic officiis? Iure, natus. Veritatis nesciunt ea, ducimus
          accusantium nisi adipisci?
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae
          quisquam accusantium, sequi laboriosam libero facilis veritatis
          nostrum ipsam sed eveniet!
        </p>
      </div>
      <div class="accept">
        <v-btn width="100%" color="error" outlined rounded>DECLINE</v-btn>
        <v-btn width="100%" color="primary" outlined rounded>ACCEPT</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Terms",
  methods: {
    ...mapActions(["pageController"]),
  },
  mounted() {
    let page = {
      title: "Terms",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
  },
};
</script>

<style>
</style>