<template>
  <header class="header elevation-2">
    <img
      @click="navigateTo(page.left.path)"
      :to="page.left.path"
      class="icon icon-left"
      :src="`/img/${page.left.icon}`"
      alt=""
    />
    <img
      @click="navigateTo(page.right.path)"
      :to="page.right.path"
      class="icon icon-right"
      :src="`/img/${page.right.icon}`"
      alt=""
      v-if="false"
    />
    <span>{{ page.title }}</span>
  </header>
</template>

<script>
export default {
  name: "Nav",
  props: {
    page: Object,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    navigateTo(path) {
      console.log({ path });
      if (path && path !== this.$route.path) {
        this.$router.push({ path });
      }
    },
  },
};
</script>

<style></style>
