<template>
  <div class="mobile-wrapper animate__animated animate__animated animate__slideInRight">
    <div class="h90">
      <div class="logo-section">
        <img src="../assets/images/rena-logo.png" alt="logo" />
      </div>
      <div class="input-section">
        <form @submit.prevent="post" action="#">
          <md-field color="primary">
            <md-icon>phone</md-icon>
            <label>Cellphone</label>
            <md-input
              type="text"
              itemid="phone"
              v-model="phone"
              name="Phone"
            ></md-input>
          </md-field>
          <md-field>
            <md-icon>lock</md-icon>
            <label>Password</label>
            <md-input
              ref="password"
              type="password"
              itemid="password"
              name="Password"
              v-model="password"
            ></md-input>
          </md-field>
          <!-- input type="submit" value="submit" / -->
        </form>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="290">
      <v-card color="white">
        <div v-if="title">
          <v-card-title class="text-h6">
            {{ title }}
          </v-card-title>
          <v-card-text
            >Please make enter valid cellphone number and password
          </v-card-text>
        </div>
        <v-card-text v-else>
          Please stand by
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="title" color="blue darken-1" text dark @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <!-- <bottom-button
      @click="post"
      style="color: #d8f2ff; background-color: #8cd6fb"
      :text="'Submit'"
    /> -->
    <div class="bottom-btn">
      <bottom-button
        text="Do not have  an account?"
        style="color: #4dd8ee"
        @do-click="goToRegister"
      />
      <bottom-button
        text="Sign In"
        style="color: #d8f2ff; background-color: #8cd6fb"
        @do-click="signin"
        @click="signin"
      />
    </div>
  </div>
</template>
<script>
import BottomButton from "../components/BottomButton.vue";
import Nav from "../components/NavBar.vue";
import { mapActions } from "vuex";
import {
  interfaceAPI,
  asyncInterfaceAPI,
} from "../helpers/Websocket";
import { CreateOrUpdateUser, getChild, addChild } from "../helpers/db";
import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import { userLogged } from "../helpers/db";
export default {
  components: { Nav, BottomButton },
  name: "Signin",
  data() {
    return {
      phone: "",
      dialog: false,
      password: "",
      title: null,
    };
  },
  methods: {
    ...mapActions(["pageController", "reDirectPage"]),
    close() {
      this.dialog = false;
      this.title = null;
    },
    async signin() {
      let inputs = document.querySelectorAll("input");
      for (let index = 0; index < inputs.length; index++) {
        console.log(inputs[index].value);
        if (!inputs[index].value) {
          inputs[index].parentNode.classList.add("md-invalid");
          this.title = `${inputs[index].name} is required`;
          this.dialog = true;
          return;
        }
      }

      this.dialog = true;
      const msg = {
        cellphone: this.phone,
        password: this.password,
        action: "LOGIN",
      };
      const result = await asyncInterfaceAPI(msg);
      console.log("AFTER LOGIN", result);
      if (typeof result === "object") {
        await CreateOrUpdateUser(result);
      } else {
        this.title = result;
      }

      const getChildMsg = {
        cellphone: result._key,
        action: "GETCHILDREN",
      };
      const data = await asyncInterfaceAPI(getChildMsg);
      data.map(async (item) => {
        console.log({ item });
        await addChild(item);
      });
      this.$router.push({ path: "profile" });
      // await interfaceAPI(msg, async (results) => {
      //   console.log({ results });
      //   if (typeof results === "object") {
      //     await CreateOrUpdateUser(results);
      //   } else {
      //     setTimeout(() => {
      //       this.title = results;
      //     }, 2000);
      //   }
      //   let getChildMsg = {
      //     cellphone: results.cellphone,
      //     action: "GETCHILDREN",
      //   };
      //   await interfaceAPI(getChildMsg, async (data) => {
      //     console.log(data);
      //     if (data.length > 0) {
      //       await data.map(async (item) => {
      //         console.log({ item });
      //         await addChild(item);
      //       });
      //     }
      //     this.$router.push({ path: "/" });
      //   });
      // });
    },

    goToRegister() {
      this.$router.push({ path: "signup" });
    },
  },
  async mounted() {
    console.log(this);
    let page = {
      title: "Log In",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
    //
    let isLogged = await userLogged();
    console.log({ isLogged });
    if (isLogged) {
      this.reDirectPage({
        path: "/signin",
        show: true,
      });
      this.$router.push({ path: "/" });
    } 
  },
};
</script>
<style>
  input {
    margin-bottom: 0 !important;
  }
</style>