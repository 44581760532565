<template>
  <div class="mobile-wrapper">
    <div class="profile">
      <v-img height="250px" src="img/profile-head.png">
        <v-card-title
          style="padding: 30px; background-color: #0000008c; min-width: 100%"
          class="white--text mt-8"
        >
          <v-list-item :key="1231">
            <v-list-item-avatar color="primary">
              <v-avatar id="primary" color="primary">
                <v-icon dark> mdi-account </v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="`${user.name} ${user.surname}`"
              ></v-list-item-title>

              <v-list-item-subtitle
                v-text="user.cellphone"
              ></v-list-item-subtitle> </v-list-item-content
          ></v-list-item>
        </v-card-title>
      </v-img>
      <div>
        <v-card outlined>
          <p class="font-weight-medium medium-text">Children</p>
          <div>
            <list-child @select-child="selectedChild" />
          </div>
          <p class="font-weight-medium medium-text">Home Address</p>
          <md-list v-if="currentChild" class="profile-details">
            <md-list-item class="list-item">
              <md-icon class="md-primary">person</md-icon>
              <span class="md-list-item-text"
                >{{ currentChild.name || user.name }}
                {{ currentChild.surname }}</span
              >
              <!--<md-icon class="md-primary">edit</md-icon>-->
            </md-list-item>
            <md-list-item class="list-item">
              <md-icon class="md-primary">location_on</md-icon>
              <span class="md-list-item-text">{{
                currentChild.homeAddress || user.homeAddress
              }}</span>
              <!--<md-icon class="md-primary">edit</md-icon>-->
            </md-list-item>
            <md-list-item class="list-item">
              <md-icon class="md-primary">school</md-icon>
              <span class="md-list-item-text">{{
                currentChild.schoolAddress || "none"
              }}</span>
              <!--<md-icon class="md-primary">edit</md-icon>-->
            </md-list-item>
          </md-list>
        </v-card>
        <!-- <pop-up-message />
        <list-child /> -->
      </div>
    </div>
    <div  style="position: absolute;height: 50px; background-color: #00d8ef" class="bottom-btn">
      <bottom-button
        text="TRACK"
        style="color: #d8f2ff; height: 100%"
        @do-click="track"
      />
    </div>
  </div>
</template>

<script>
import BottomButton from "./BottomButton.vue";
import { mapActions } from "vuex";
import PopUpMessage from "./PopUpMessage.vue";
import ListChild from "./ListChild.vue";
import { getUser } from "../helpers/db";
import { asyncInterfaceAPI } from "../helpers/Websocket";
export default {
  name: "Profile",
  data() {
    return {
      value: true,
      user: {},
      currentChild: undefined,
    };
  },
  components: { BottomButton, PopUpMessage, ListChild },
  async created() {
    let user = await getUser();
    this.user =
      user.length > 0
        ? user[0]
        : {
            name: "Guest",
            surname: "",
            idNumber: "",
          };
    let cellphone = await user[0].cellphone;
    let vapidKey = process.env.VUE_APP_FB_KEY;
    //console.log({vapidKey, cellphone, eval: process.env});
    try {
      const permission = await Notification.requestPermission();
      console.log(permission)
      if (permission === "granted") {
        const token = await messaging.getToken({ vapidKey });
        if (token !== null) {
          const msg = { cellphone, action: "UPDATETOKEN", token };
          console.log(msg)
          await asyncInterfaceAPI(msg);
        }
      }
    } catch (error) {
      console.log(
        "requestPermission() FAILED  || MSG: ",
        error.message,
        "NAME:",
        error.name
      );
    }
  },
  async mounted() {
    let page = {
      title: "Profile",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: true,
        icon: "refresh-icon.svg",
      },
    };
    console.log(this);
    this.pageController(page);
  },
  methods: {
    ...mapActions(["pageController"]),
    navigateTo(path) {
      this.$router.push({ path });
    },
    selectedChild(data) {
      console.log(data);
      this.currentChild = Object.assign({}, data);
    },
    track() {
      this.navigateTo("/map");
    },
  },
};
</script>

<style>
.list-item,
.profile-details > .md-list {
  background-color: #e7f0f7 !important;
}
</style>
