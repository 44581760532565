<template>
  <div
    class="mobile-wrapper"
  >
    <div class="h90">
      <div class="logo-section">
        <img src="../assets/images/rena-logo.png" alt="logo" />
      </div>
      <div class="input-section">
        <form @submit.prevent="post" action="#">
          <md-field>
            <md-icon>person</md-icon>
            <label>Name</label>
            <md-input
              aria-required="true"
              type="text"
              itemid="name"
              name="Name"
              v-model="name"
            ></md-input>
          </md-field>
          <md-field>
            <md-icon>person</md-icon>
            <label>Surname</label>
            <md-input
              type="text"
              name="Surname"
              itemid=""
              v-model="lastname"
            ></md-input>
          </md-field>
          <md-field>
            <md-icon>badge</md-icon>
            <label>ID no</label>
            <md-input
              type="text"
              itemid="idNumber"
              name="ID Number"
              v-model="idNumber"
            ></md-input>
          </md-field>
          <md-field color="primary">
            <md-icon>school</md-icon>
            <label>School Name</label>
            <md-input
              type="text"
              itemid=""
              name="School Name"
              v-model="schoolName"
            ></md-input>
          </md-field>
          <md-field>
            <v-autocomplete
              class="auto-complete-bar"
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              clearable
              hide-details
              hide-selected
              item-text="description"
              item-value="symbol"
              label="School Address"
              name="School Address"
              placeholder="Type School Address"
              solo
              color="primary"
              prepend-inner-icon="mdi-map-marker"
            >
              <template v-if="items.length === 0" class="search-results">
                <v-list-item>
                  <v-list-item-title>
                    Type <strong>School Address</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="items.length > 0" class="search-results">
                <div v-for="(item, index) in items" :key="index">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.description"
                    ></v-list-item-title>
                  </v-list-item-content>
                </div>
              </template>
            </v-autocomplete>
          </md-field>
          <!-- <md-field>
            <md-icon>school</md-icon>
            <label>School Address</label>
            <md-input type="text" itemid="" v-model="schoolAddress"></md-input>
          </md-field> -->
          <!-- <input @submit.prevent="post" type="submit" value="Submit" /> -->
        </form>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card color="white">
        <div v-if="title">
          <v-card-title class="text-h6">
            {{ title }}
          </v-card-title>
          <v-card-text
            >Please make sure all fields are filled in correctly</v-card-text
          >
        </div>
        <v-card-text v-else>
          Please stand by
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="title"
            color="blue darken-1"
            text
            dark
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <div
      style="position: absolute;height: 50px;"
      class="bottom-btn"
    >
      <!-- <bottom-button
        :text="'Already a have an account?'"
        style="color: #4dd8ee"
        @click="navigateTo('/signin')"
      ></bottom-button> -->
      <!-- @click="navigateTo('lool')" -->
      <!-- @click="post" -->
      <bottom-button
        @do-click="post"
        text="Add Child"
        style="color: #d8f2ff; background-color: #8cd6fb"
      ></bottom-button>
    </div>
  </div>
</template>

<script>
import BottomButton from "../components/BottomButton.vue";
import Nav from "../components/NavBar.vue";
import { v4 as uuidv4 } from "uuid";
import { interfaceAPI, asyncInterfaceAPI } from "../helpers/Websocket";
import { addChild, getUser } from "../helpers/db";
import { mapActions } from "vuex";
export default {
  components: { Nav, BottomButton },
  name: "AddChild",
  data() {
    return {
      parent: {},
      email: "",
      name: "",
      idNumber: "",
      lastname: "",
      schoolName: "",
      schoolAddress: "",
      //
      dialog: false,
      title: "",
      //
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      sessiontoken: "",
      currentAddress: undefined,
      locationCoords: undefined,
    };
  },
  watch: {
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    async search(search) {
      console.log(search.length);
      if (search.length < 4) return;
      console.log(this.sessiontoken);
      const msg = {
        action: "GETPLACES",
        search,
        sessiontoken: this.sessiontoken,
      };
      this.isLoading = true;

      const result = await asyncInterfaceAPI(msg);
      console.log("RESULT", result);
      this.isLoading = false;
      this.items = await result;
      this.currentAddress = await result[0];

      // let config = {
      //   url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${val}&language=en&components=country:za&sessiontoken=${this.sessiontoken}&key=${process.env.VUE_APP_PLACES_KEY}`,
      //   method: "get",
      //   mode: "no-cors",
      //   cache: "no-cache",
      //   referrerPolicy: "no-referrer",
      //   headers: {
      //     //  "Referrer-Policy" :"unsafe-url",
      //     "Content-type": "application/json; charset=UTF-8",
      //     "Access-Control-Allow-Origin": "http://localhost:433/",
      //     "Access-Control-Allow-Headers":
      //       "Origin, X-Requested-With, Content-Type, Accept",
      //   },
      // };
      // let url = `https://cors-anywhere.herokuapp.com/${config.url}`;

      // fetch(url)
      //   //  fetch('https://api.coingecko.com/api/v3/coins/list')
      //   .then((res) => res.json())
      //   .then((res) => {
      //     console.log({ res });
      //     this.items = res.predictions;
      //     this.currentAddress = res.predictions[0];
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => (this.isLoading = false));

      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions(["pageController", "showModal"]),
    navigateTo(path) {
      if (path && path !== this.$route.path) {
        this.$router.push({ path });
      }
    },

    async post() {
      let inputs = document.querySelectorAll("input");
      for (let index = 0; index < inputs.length; index++) {
        console.log(inputs[index].value);
        if (!inputs[index].value) {
          inputs[index].parentNode.classList.add("md-invalid");
          this.title = `${inputs[index].name} is required`;
          this.dialog = true;
          return;
        }
      }
      // console.log(e);
      this.dialog = true;
      let msg = {
        action: "GETPLACECOORDINATES",
        placeId: this.currentAddress.place_id,
      };
      console.log(msg);
      this.locationCoords = await asyncInterfaceAPI(msg);

      let msgAddChild = {
        cellphone: this.parent.cellphone,
        scholarData: {
          firstName: this.name,
          profilePic: `https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=${this.name.replace(
            /\s+/g,
            ""
          )}+${this.lastname.replace(/\s+/g, "")}`,
          surname: this.lastname,
          idNumber: this.idNumber,
          schoolName: this.schoolName,
          schoolAddress: this.search,
          schoolLocation: {
            latitude: this.locationCoords.lat,
            longitude: this.locationCoords.lng,
          },
        },
        action: "ADDCHILD",
      };
      console.log({ MSG_THECHILD: msg });
      const results = await asyncInterfaceAPI(msgAddChild);
      this.dialog = false;
      if (
        results ===
        `Scholar with the Id Number ${this.idNumber} is already addedd` //dont fix this typo PLEASE
      ) {
        this.showModal({
          theme: "error",
          mssg: `Scholar with the ID Number ${this.idNumber} is already added.`,
          show: true,
        });
        return;
      }

      await addChild({
        name: this.name,
        surname: this.lastname,
        profilePic: `https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=${this.name.replace(
          /\s+/g,
          ""
        )}+${this.lastname.replace(/\s+/g, "")}`,
        idNumber: this.idNumber,
        schoolName: this.schoolName,
        schoolAddress: this.search,
        schoolLocation: {
          latitude: this.locationCoords.lat,
          longitude: this.locationCoords.lng,
        },
      });

      if (results === "Scholar Linked to Parent") {
        this.showModal({
          theme: "success",
          mssg: results,
          show: true,
        });
        // this.$router.push({ path: "/" });
        this.email = "";
        this.name = "";
        this.idNumber = "";
        this.lastname = "";
        this.schoolName = "";
        this.schoolAddress = "";
      }
      this.navigateTo("/profile");
      // interfaceAPI(msgAddChild, async (results) => {
      // 	console.log('THEN DO MORE WITH', { results });
      // 	this.dialog = false;

      // 	if (
      // 		results ===
      // 		`Scholar with the Id Number ${this.idNumber} is already added`
      // 	) {
      // 		this.showModal({
      // 			theme: 'error',
      // 			mssg: results,
      // 			show: true,
      // 		});
      // 		return;
      // 	}
      // 	///
      // 	await addChild({
      // 		name: this.name,
      // 		surname: this.lastname,
      // 		profilePic: `https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=${this.name.replace(
      // 			/\s+/g,
      // 			''
      // 		)}+${this.lastname.replace(/\s+/g, '')}`,
      // 		idNumber: this.idNumber,
      // 		schoolName: this.schoolName,
      // 		schoolAddress: this.search,
      // 		schoolLocation: {
      // 			latitude: this.locationCoords.lat,
      // 			longitude: this.locationCoords.lng,
      // 		},
      // 	});
      // 	if (results === 'Scholar Linked to Parent') {
      // 		this.showModal({
      // 			theme: 'success',
      // 			mssg: results,
      // 			show: true,
      // 		});
      // 		// this.$router.push({ path: "/" });
      // 		this.email = '';
      // 		this.name = '';
      // 		this.idNumber = '';
      // 		this.lastname = '';
      // 		this.schoolName = '';
      // 		this.schoolAddress = '';
      // 	}
      // });
    },
  },
  async mounted() {
    this.sessiontoken = uuidv4();
    console.log(this);
    let u = await getUser();
    this.parent = u[0];
    let page = {
      title: "Add Child",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
    //
  },
};
</script>
<style>
input {
  margin-bottom: 0 !important;
}
.md-field label {
  top: 10px !important;
}
.md-focused label,
.md-has-value label {
  top: 0 !important;
}
</style>
