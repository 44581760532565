<template>
  <div
    class="
      mobile-wrapper
      animate__animated animate__animated animate__slideInRight
    "
  >
    <div class="h90 messages">
      <div v-for="(mssg, index) in messages" :key="index">
        <v-list-item @click="clickMssg(mssg)">
          <!-- <v-list-item-avatar>
          <v-img
            id="v-img"
            :src="'https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=PhyllisKramer'"
          ></v-img>
        </v-list-item-avatar> -->
          <v-list-item-content>
            <v-list-item-title v-html="mssg.header"></v-list-item-title>
            <v-list-item-subtitle
              :title="mssg.message"
              v-html="mssg.message"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text
              v-text="time(mssg.timeStamp)"
            ></v-list-item-action-text>
            <v-icon color="grey lighten-1"> </v-icon
          ></v-list-item-action>
        </v-list-item>
        <v-divider style="margin: 0" :key="mssg.message"></v-divider>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card color="white">
        <div v-if="title">
          <v-card-title class="text-h6">
            {{ title }}
          </v-card-title>
          <v-card-text>
            {{ mssg }}
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text dark @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { asyncInterfaceAPI } from "../helpers/Websocket";
import { getUser } from "../helpers/db";
export default {
  name: "Messages",
  data() {
    return {
      dialog: false,
      title: "",
      mssg: "",
      items: [
        12, 32, 46, 5666, 6664, 569, 6466, 5, 426, 54, 6, 7, 4, 326, 7, 8,
        755555, 59, 759, 77, 47, 423,
      ],
      messages: [],
    };
  },
  methods: {
    ...mapActions(["pageController"]),
    time(unix_timestamp) {
      // unix_timestamp = Number(unix_timestamp) === Number(1634036730)? 1630447200 : unix_timestamp
      let date = new Date(unix_timestamp * 1000);
      let hours = date.getHours();
      let minutes = "0" + date.getMinutes();
      let formattedTime = hours + ":" + minutes.substr(-2);
      let today = Date();
      let todayStr = today.slice(4, 9);
      let day = `${date}`.slice(4, 9);
      // console.log({ formattedTime, date, day, today, todayStr });
      // console.log(todayStr !== day);
      return todayStr !== day ? `${todayStr}, ${formattedTime}` : formattedTime;
    },
    clickMssg(data) {
      this.title = data.header;
      this.mssg = data.message;
      this.dialog = true;
    },
  },
  async mounted() {
    let page = {
      title: "Messages",
      mssg: "",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    console.log(this);
    this.pageController(page);
    let user = await getUser();
    let cellphone = user[0].cellphone;

    let msg = {
      action: "GETPARENTMESSAGES",
      cellphone,
    };

    let data = await asyncInterfaceAPI(msg);

    this.messages = data;
  },
};
</script>
<style>
.mobile-wrapper > div.messages {
  width: 100% !important;
}
.messages .v-image__image {
  border: 2px solid #4dd8ee !important;
  border-radius: 50% !important;
}
</style>