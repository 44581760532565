import Vue from 'vue'
import VueRouter from 'vue-router'
import SplashInitial from '../views/SplashInitial.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import Welcome from '../components/Welcome.vue'
import NavBar from '../components/NavBar.vue'
import Settings from "../views/Settings.vue"
import PaymentMethod from "../views/PaymentMethod.vue"
import Map from '../views/Map.vue';
import Profile from '../components/Profile.vue';
import ChildProfile from '../views/ChildProfile.vue';
import SecProfile from "../views/ChildProfile.vue";
import LiftHistory from '../views/LiftDetails.vue';
import Menu from '../components/Menu.vue'
import AddChild from '../components/AddChild.vue'
import Terms from '../views/Terms.vue';
import Error from '../views/Error.vue'
import Messages from '../views/Messages.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'SplashInitial',
    component: SplashInitial
  },
  {
    path: '/nav',
    name: 'Nav',
    component: NavBar
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/add-child',
    name: 'AddChild',
    component: AddChild
  },
  {
    path: '/',
    name: 'Map',
    component: Map
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/child-profile',
    name: 'ChildProfile',
    component: ChildProfile
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/payment-method',
    name: 'PaymentMethod',
    component: PaymentMethod
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/lift-history/:id',
    name: 'LiftHistory',
    component: LiftHistory
  },
  {
    path: '/lift-history',
    name: 'LiftHistory',
    component: LiftHistory
  },
  {
      path: "/sec-profile",
      name: "SecProfile",
      component: SecProfile
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '*',
    name: 'Error',
    component:Error
  },

]

const router = new VueRouter({
  routes
})

export default router
