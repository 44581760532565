<template>
  <!-- <div id="container" class="splashinitial-container" style="overflow: hidden"> -->
  <!-- Include picture with the App name : Need two separate pictures as one with app name is not full -->

  <!-- <img class="initial-image" src="../assets/images/Rena_Android_Image_1.jpg" :class="{loaded: loaded }" /> -->
  <section class="h100 animate__animated animate__animated animate__slideInUp">
    <div class="btn-section">
      <RoundButton
        path="/signin"
        class="signin-button"
        textColor="white"
        text="Sign In"
        opacity="0.4"
        v-if="false"
      />
      <RoundButton
        path="/signup"
        class="signup-button"
        textColor="#5ddaf3"
        text="Sign Up"
        v-if="false"
      />
    </div>
  </section>
  <!-- </div> -->
</template>

<script>
import { mapActions } from "vuex";
import RoundButton from "../components/RoundButton.vue";
import { userLogged } from "../helpers/db";
import {sleep} from "../helpers/commonTasks"

export default {
  name: "SplashInitial",
  data: function () {
    return {
      loaded: false,
    };
  },
  methods: {
    ...mapActions(["pageController"]),
    navigateTo(path) {
      if (path && path !== this.$route.path) {
        this.$router.push({ path });
      }
    },
  },
  components: {
    RoundButton,
  },
  computed: {
    currentStyle: function currentStyle() {
      var self = this;
      return self.loaded;
    },
  },
  async mounted() {
    var self = this;
    this.pageController();
    await sleep(2500)
    const isLogged = await userLogged();
    console.log({ isLogged });
    if (isLogged === false) {
     this.navigateTo('signin')
    }
    else{
      this.navigateTo('profile')
    }
   
  },
  
};
</script>

<style scoped>
.splashinitial-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

img {
  pointer-events: none;
  position: absolute;
  max-width: 227px;
  height: 45vh;
  top: 25vh;
  left: 25vw;
  z-index: 0;
  transition: transform 1s;
}

.loaded {
  transform: scale(2) translateX(-2vw) translateY(3.7vh);
}
</style>
