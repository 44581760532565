<template>
  <l-map :zoom="zoom" :center="center" :bounds="mapBounds">
    <l-tile-layer :attribution="attribution" :url="url"></l-tile-layer>
    <l-polyline
      :lat-lngs="polyline.latlngs"
      :color="polyline.color"
    ></l-polyline>
       <l-marker
      v-if="path !== []"
      :icon="iconA"
      class="point-a"
      :lat-lng="markerA"
    >
    </l-marker>
    <l-marker
      v-if="path !==[]"
      :icon="iconB"
      class="point-b"
      :lat-lng="markerB"
    >
    </l-marker>
  </l-map>
</template>

<script>
import { latLng, Icon, latLngBounds } from "leaflet";
import { LMap, LTileLayer, LPolyline, LMarker, } from "vue2-leaflet";
export default {
  name: "TripMap",
  components: {
    LMap,
    LTileLayer,
    LPolyline,
       LMarker,
  },
  data() {
    return {
        iconA: L.icon({
        iconUrl:
          "/images/Rectangle1476.png",
        iconSize: [12, 12],
        // iconAnchor: [16, 37],
      }),
      iconB: L.icon({
        iconUrl:
          "/images/Symbol72.png",
        iconSize: [20, 20],
        iconAnchor: [10, 22],
      }),

      center: [47.31322, -1.319482],
      zoom: 8,
      attribution:
        '<a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      mapBounds: latLngBounds([
        [-23.157, 30.0759183],
        [-34.1565133, 18.6004583],
      ]),
      polyline: {
        latlngs: [],
        color: "#27adff",
      },
      markerA: [],
      markerB: [],
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  },
  props: {
    path: Array,
  },
  async mounted() {
    console.log('HERE ')
    let tripData = this.path;
    let latlanPoints = undefined
    if(tripData.length < 1)return
    //
    let pointA = tripData[0];
    let pointB = tripData[tripData.length - 1];
    console.log({ pointA, pointB });

    try{
      this.markerA = [pointA.latitude, pointA.longitude];
      this.markerB = [pointB.latitude, pointB.longitude];
      //
      console.log(this.path.length);
      latlanPoints = tripData.map(m=>[parseFloat(m.latitude), parseFloat(m.longitude)] );
      this.mapBounds = await latLngBounds(latlanPoints);
      this.polyline.latlngs = latlanPoints;
    }
    catch(e){
      console.error(e)
      console.log(tripData)
      console.log(latlanPoints)
    }
    
  },
};
</script>

<style>
</style>