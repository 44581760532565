<template>
  <v-toolbar absolute style="top: 67px" color="#71e8f5cf">
    <!-- <v-app-bar-nav-icon class="hidden-sm-and-down"></v-app-bar-nav-icon> -->
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      chips
      clearable
      hide-details
      hide-selected
      item-text="name"
      item-value="symbol"
      label="Search..."
      solo
      prepend-inner-icon="mdi-map-marker"
    >
      <template class="search-results" v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
           Type
            <strong>School Address</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template class="search-results"  v-slot:item="{ item }">
        <v-list-item-avatar
          color="#71e8f5cf"
          class="text-h5 font-weight-light white--text"
        >
          {{ item.name.charAt(0) }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <!-- <v-icon>mdi-bitcoin</v-icon> -->
        </v-list-item-action>
      </template>
    </v-autocomplete>
  </v-toolbar>
</template>

<script>
  export default {
      name: "SearchBar",
    data: () => ({
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
    }),

    watch: {
      model (val) {
        if (val != null) this.tab = 0
        else this.tab = null
      },
      search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        this.isLoading = true

        // Lazily load input items
        fetch('https://api.coingecko.com/api/v3/coins/list')
          .then(res => res.clone().json())
          .then(res => {
            this.items = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
  }
</script>

<style>

</style>