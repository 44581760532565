let placeHolderPage = {
  title: "Rena",
  show: false,
  left: {
    path: "/map",
    visible: true,
    icon: "menu-icon.svg",
  },
  right: {
    path: null,
    visible: true,
    icon: "refresh-icon.svg",
  },
};

const state = {
  page: placeHolderPage,
  modal: {
    show: false,
    theme: "error",
    mssg: "wateva"
  },
  redirected: {
    path: "/_dash",
    show:false,
    theme: "error"
  }
};

const getters = {
  getPage: (state) => state.page,
  getModal: (state) => state.modal,
  getReDirect: (state) => state.redirected
};

const actions = {
  pageController({
    commit
  }, page = placeHolderPage) {
    commit("setPage", page);
  },
  reDirectPage({
    commit
  }, data) {
    console.log("REDIRECT", {
      data
    });
    commit("setReDirect", data)
  },
  showModal({
    commit
  }, data) {
    console.log("REDIRECT", {
      data
    });
    commit("setModal", data)
  }
};
const mutations = {
  setPage: (state, page) => (state.page = page),
  setReDirect: (state, data) => (state.redirected = data),
  setModal: (state, data) => (state.modal = data)
};

export default {
  state,
  getters,
  actions,
  mutations,
};