<template>
  <div>
    <v-bottom-sheet
      max-width="600px"
      width="100%"
      hide-overlay
      class="bottom-sheet"
      v-model="sheet"
      persistent
    >
      <v-sheet class="text-center" height="220px">
        <!-- <v-btn class="mt-6" text color="error" >
        </v-btn> -->
        <!-- <div class="py-3">{{child.name}}</div> -->
        <div class="trip-data">
          <div key="2">
            <v-avatar width="48" height="48" min-width="48" class="white-bg">
                <img
                  style="width: 100%; height: 100%"
                  :src="`https://ui-avatars.com/api/?background=fff&color=4dd8ee&name=${child.name}`"
                />
            </v-avatar>
                {{child.homeAddress}}
              <img src="../assets/images/toyota-side-view.png" height="60" width="120" alt="ui-display">
          </div>
          <v-spacer  style="margin:48px 0 !important">
               <v-icon class="empty" x-small></v-icon>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--light"
            />
               <v-icon small>mdi-map-marker</v-icon>
          </v-spacer>
          <div key="13">
            <v-avatar width="48" height="48" min-width="48" class="white-bg">
                <v-icon color="primary">mdi-domain</v-icon>
            </v-avatar>
           {{child.schoolName}}
          </div>
        </div>
        <div
          style="
            height: 100px;
            background-color: #00d8ef;
            position: absolute;
            max-width: 600px;
            bottom: 0;
            width: 100%;
          "
        >
          <bottom-button
          key="btn-01"
          @do-click="navigateToTrips"
            text="Lift History"
            style="color: #d8f2ff; height: 50%;border: 1px solid #d8f2ff;max-width: 600px !important"
          />
          <bottom-button
                 key="btn-02"
          @do-click="close"
            text="Close"
            style="color: #d8f2ff; height: 50%; max-width: 600px !important"
          />
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import BottomButton from "./BottomButton.vue";
export default {
  components: { BottomButton },
  name: "BottomSheet",
  props: {
    sheet: {
      type: Boolean,
      default: false,
    },
    child: Object,
    close: Function,
    navigateToTrips: Function
  },
  data() {
    return {
      value: true,
    };
  },
};
</script>

<style>
</style>