<template>
  <div class="mobile-wrapper">
    <div class="child-profile">
      <v-card-title
        style="padding: 30px 0; background-color: #f1f9ff"
        class="white--text mt-8"
      >
        <v-list-item :key="1231">
          <v-list-item-avatar color="primary">
            <v-avatar id="primary" color="primary">
              <v-icon dark> mdi-account </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="'Lorem text here'"></v-list-item-title>
            <!-- <v-list-item-subtitle
                v-text="'Some more Lorem text here.'"
              ></v-list-item-subtitle>-->
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <div class="edit-details">
        <p class="font-weight-medium medium-text">Home Address</p>
        <md-list class="profile-details">
          <md-list-item class="list-item">
            <md-icon class="md-primary">person</md-icon>
            <span class="md-list-item-text">Nimrod Mnisi</span>
             <md-icon class="md-primary">pencil</md-icon>
          </md-list-item>
          <md-list-item class="list-item">
            <md-icon class="md-primary">location_on</md-icon>
            <span class="md-list-item-text">Khayelitsha, WC</span>
             <md-icon class="md-primary">pencil</md-icon>
          </md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ChildProfile",
  data() {
    return {
      value: "Some Text",
    };
  },
  methods: {
    ...mapActions(["pageController"]),
  },
  mounted() {
    let page = {
      title: "Child Profile",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
  },
};
</script>

<style>
</style>