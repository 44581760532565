<template>
  <div class="settings-wrapper animate__animated animate__animated animate__slideInRight">
    <div v-if="!user" class="text-center center h10">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="settings">
      <md-list class="profile-details">
        <md-list-item>
          <md-icon class="md-primary">person</md-icon>
          <span class="md-list-item-text"
            >{{ user.name }} {{ user.surname }}</span
          >
               <!--<md-icon>edit</md-icon>-->
        </md-list-item>
        <md-list-item>
          <md-icon class="md-primary">location_on</md-icon>
          <span class="md-list-item-text">{{ user.homeAddress }}</span>
             <!--<md-icon>edit</md-icon>-->
        </md-list-item>
        <md-list-item>
          <md-icon class="md-primary">mail</md-icon>
          <span class="md-list-item-text">{{ user.email || "*none*" }}</span>
               <!--<md-icon>edit</md-icon>-->
        </md-list-item>
        <md-list-item>
          <md-icon class="md-primary">phone</md-icon>
          <span class="md-list-item-text">{{ user.cellphone }}</span>
               <!--<md-icon>edit</md-icon>-->
        </md-list-item>
        <md-list-item>
          <md-icon class="md-primary">lock</md-icon>
          <span class="md-list-item-text">***********</span>
               <!--<md-icon>edit</md-icon>-->
        </md-list-item>
      </md-list>
      <md-list v-if="settings" class="notification-settings">
        <md-list-item v-for="(item, index) in settings" :key="index">
          <md-icon class="md-primary">notifications</md-icon>
          <span class="md-list-item-text">{{ item.name }}</span>
          <md-switch @change="settChng(item)" v-model="item.value" />
        </md-list-item>
      </md-list>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getUser, CreateOrUpdateUser } from "../helpers/db";
import { interfaceAPI } from "../helpers/Websocket";
export default {
  name: "Settings",
  data: function () {
    return {
      user: undefined,
      settings: undefined,
    };
  },
  methods: {
    ...mapActions(["pageController"]),
    settChng(current) {
      console.log(current);
      let msg = {
        action: "TOGGLEUSERSETTING",
        cellphone: this.user.cellphone,
        settingId: current.id,
      };
      console.log({ msg });
      this.user.settings[current.id] = current.value ? 1 : 0;
      interfaceAPI(msg, (data) => {
        console.log("returneddata", { data });
        if (data === "DONE") {
          CreateOrUpdateUser(this.user);
        }
      });
    },
    async settUpdate(settings) {
      let { sendDropOffAlert, sendPickUpAlert } = settings;
      this.settings = [
        // {
        //   value: Boolean(sendDrivingAlert),
        //   name: "Driving Alert",
        //   sendDrivingAlert,
        //   id: "sendDrivingAlert",
        // },
        {
          value: Boolean(sendDropOffAlert),
          name: "DropOff Alert",
          sendDropOffAlert,
          id: "sendDropOffAlert",
        },
        {
          value: Boolean(sendPickUpAlert),
          name: "PickUp Alert",
          sendPickUpAlert,
          id: "sendPickUpAlert",
        },
      ];
    },
  },
  watch: {
    settings(val) {
      console.log(val);
    },
  },
  async mounted() {
    let page = {
      title: "Settings",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: false,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
    let user = await getUser();
    console.log(user);
    this.user = user[0];
    await this.settUpdate(user[0].settings);
  },
};
</script>

<style>
</style>