<template>
  <button  class="btn-element"
    @click="doClick"
  >
    <b>{{text}}</b>
  </button>
</template>

<script>
export default {
  name: "BottomButton",
  props: {
    text:{
      type:  String,
      default: ""
    }
  },
  methods:{
    doClick(){      
      this.$emit("do-click", "centre");
    }
  }
};

</script>

<style>
</style>