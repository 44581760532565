<template>
  <div class="map-wrapper">
    <!-- <search-bar class="search-bar" /> -->
    <list-child @select-child="selectChild" class="account-details" />
    <leafleat
      :currentChild="selectedChild"
      :hasPath="bottomSheet"
      :path="selectedChildTrip"
      class="leaflet-map"
    />
    <bottom-sheet
      v-if="selectedChild"
      :child="selectedChild"
      :close="close"
      :navigateToTrips="navigateToTrips"
      :sheet="bottomSheet"
    />
  </div>
</template>
<script>
import BottomSheet from "../components/BottomSheet.vue";
import Leafleat from "../components/Leafleat.vue";
import SearchBar from "../components/SearchBar.vue";
import { mapActions, mapGetters } from "vuex";
import ListChild from "../components/ListChild.vue";
import { getChild } from "../helpers/db";
import { interfaceAPI, asyncInterfaceAPI } from "../helpers/Websocket";
import { sleep } from "../helpers/commonTasks";
export default {
  name: "Map",
  data() {
    return {
      value: null,
      selectedChild: null,
      selectedChildTrip: undefined,
      bottomSheet: false,
      interval: undefined,
    };
  },
  components: {
    Leafleat,
    SearchBar,
    BottomSheet,
    ListChild,
  },
  watch: {
    selectedChildTrip: async function (value) {
      // console.log('SELECTED CHILD ',value, this.selectedChild);
      setTimeout(async () => {
        await this.getTrips(this.selectedChild.idNumber);
      }, 1500);
    },
  },
  methods: {
    ...mapActions(["pageController", "updateCurrentTrips", "showModal"]),
    async selectChild(data) {
      this.selectedChild = data;
      await this.getTrips(data.idNumber);
      if (this.selectedChildTrip !== undefined) {
        this.bottomSheet = true;
        console.log("SHOW BOTTOM SHEET", this.selectedChildTrip, data);
        this.showModal({
          show: false,
          theme: "success",
          mssg: `${this.selectedChild.name} is in transit.`,
        });
      } else {
        this.bottomSheet = false;
        // console.log('SHOW MODAL', this.selectedChildTrip, data);
        this.showModal({
          show: true,
          theme: "error",
          mssg: `${this.selectedChild.name} is not in transit.`,
        });
      }

      //   console.log("THIS PATH IZZZZZZ ", this.path);
      // if (this.path === undefined) {
      // }
    },
    navigateToTrips() {
      this.$router.push({
        path: `lift-history/${this.selectedChild.idNumber}`,
      });
    },
    async getTrips(id) {
      // clearInterval(this.interval)
      let msg = {
        action: "GETSCHOLARTRIPS",
        idNumber: id,
        tripDate: this.date(),
      };
      // console.log({ msg, id });
      const data = await asyncInterfaceAPI(msg);
      this.selectedChildTrip = data.length > 0 ? data[0].path : undefined;
    },
    close() {
      this.bottomSheet = false;
    },
    date() {
      let date = new Date();
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      // return `${day}-${month}-${year}`;
      return `${year}-${month}-${day}`;
    },
  },

  async mounted() {
    let page = {
      title: "Location",
      show: true,
      left: {
        path: "/menu",
        visible: true,
        icon: "menu-icon.svg",
      },
      right: {
        path: null,
        visible: true,
        icon: "refresh-icon.svg",
      },
    };
    this.pageController(page);
  },
};
</script>
<style scoped></style>
