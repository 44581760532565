<template>
  <div class="roundbutton-container">
    <button @click="navigateTo(path)" :style="Style">{{ text }}</button>
  </div>
</template>

<script>
export default {
  name: "RoundButton",
  props: {
    path: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: "255, 255, 255",
    },
    text: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    opacity: {
      type: String,
      default: "",
    },
  },
  methods: {
    navigateTo(path) {
      if (path && path !== this.$route.path) {
        this.$router.push({ path });
      }
    },
  },
  computed: {
    Style: function () {
      var self = this;
      // return self.backgroundColor
      return `
            background-color: rgba(${
              self.backgroundColor
                ? self.backgroundColor.toString()
                : "255, 255, 255"
            }, ${self.opacity ? self.opacity : 1});
                        border: none;
                        border-radius: 46px;
                        color: ${self.textColor};
                        padding: 7px 32px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                        width:100%;
                    `;
    },
  },
};
</script>

<style>
button {
  font-weight: 600 !important;
}
</style>
